import Analytics from 'analytics';
import * as Plugin from './plugin';
import * as StorageUtils from '@analytics/storage-utils';

/**
 * @typedef {{apiUrl: string, datasourceId: string, enabled: boolean, trackGoogleDataLayer: boolean, log: boolean}} WrapperConfig
 */

class Wrapper {

	static #consentKey = '__cdp_consent__';

	#startConfig;
	#config;
	#analytics;
	#plugin;

	/**
	 * @param {WrapperConfig} config
	 */
	constructor(config) {
		this.#startConfig = Object.assign({}, config);

		if (config.enabled === undefined) {
			config.enabled = this.hasConsent();
		}

		this.#config = Object.assign({}, config);
		this.#analytics = Analytics({
			app: config.app,
			plugins: [
				Plugin.create(config, true)
			]
		});

		this.#plugin = this.#analytics.plugins['cdp-analytics'];
		!config.enabled && this.#analytics.plugins.disable('cdp-analytics');

		(config.enabled && config.trackGoogleDataLayer) && this.initGoogleDataLayer();
	}

	hasConsent() {
		const consent = StorageUtils.getItem(Wrapper.#consentKey);
		return consent === "consent";
	}

	giveConsent() {
		StorageUtils.setItem(Wrapper.#consentKey, "consent");
		this.#config.enabled = true;
		this.#analytics.plugins.enable('cdp-analytics');
	}

	revokeConsent() {
		StorageUtils.removeItem(Wrapper.#consentKey);
		this.#config.enabled = false;
		this.#analytics.plugins.disable('cdp-analytics');
	}

	initGoogleDataLayer() {
		// our backing array
		const array = window.dataLayer || [];
		const self = this;

		if (array.length > 0) {
			for (const element of array) {
				element.event && this.trackGoogleDataLayerEvent(element);
			}
		}

		// a proxy for our array
		window.dataLayer = new Proxy(array, {
			apply: function(target, thisArg, argumentList) {
				return thisArg[target].apply(this, argumentList);
			},
			deleteProperty: function(target, property) {
				return true;
			},
			set: function(target, property, value, receiver) {
				target[property] = value;
				// noinspection JSCheckFunctionSignatures
				if (!isNaN(property) && value.event) {
					self.trackGoogleDataLayerEvent(value);
				}
				return true;
			}
		});
	}

	trackGoogleDataLayerEvent(event) {
		this.#config.log && console.log("Added %o to dataLayer (%s)", event);
	}

	page(name, data) {
		if (name === undefined) {
			this.#analytics.page();
		}
		else if (typeof name !== 'string') {
			this.#analytics.page(name);
		}
		else {
			data = data || {};
			data.event = name;
			this.#analytics.page(data);
		}
	}

	track(name, data) {
		this.#analytics.track(name, data);
	}

	trackForm(form, formId) {
		const formData = new FormData(form);
		const properties = {};
		let name, value;

		for (const entry of formData.entries()) {
			name = entry[0];
			value = entry[1];

			if (properties[name] !== undefined) {
				if (Array.isArray(properties[name])) {
					properties[name].push(value);
				}
				else {
					properties[name] = [properties[name], value];
				}
			}
			else {
				properties[name] = value;
			}
		}

		formId = formId || form.id;

		if (formId && properties.formId === undefined) {
			properties.formId = formId;
		}

		this.track('form-submit', properties);

		return true;
	}

	identify(userId, traits) {
		this.#analytics.identify(userId, traits);
	}

	group(groupId, traits) {
		this.#plugin.group(groupId, traits);
	}

}

// noinspection JSUnusedGlobalSymbols
export function create(config) {
	return new Wrapper(config);
}