/**
 * @typedef {{path: string}} ServerViewRuleConfig
 */

/**
 * @typedef {{element: string, selector: string, type: string}} ServerTrackRuleConfig
 */

/**
 * @typedef {{id: string, name: string, type: string, configuration: ServerViewRuleConfig|ServerTrackRuleConfig}} ServerRule
 */

/**
 * @typedef {{rules: Array.<ServerRule>, updatedOn: string}} RuleSetResponse
 */

export class ViewRule {
	/** @type {string} */
	#path = undefined;
	/** @type {string} */
	#name = undefined;
	/** @type {Array.<string>} */
	#parts = [];
	/** @type {number} */
	#matchLength = 0;
	/** @type {boolean} */
	#matchStart = true;

	constructor(path, name) {
		this.#path = path;
		this.#name = name;

		this.#parts = this.#path.split("*");
		this.#matchLength = this.#parts.reduce((len, part) => part.length + len, 0);
		this.#matchStart = this.#parts[0] !== "";
	}

	get path() {
		return this.#path;
	}

	get name() {
		return this.#name;
	}

	computeMatch(url) {
		// Extract the path from the URL starting from the first '/'
		let path = url.includes("/") ? url.substring(url.indexOf("/")) : url;
		// drop the query string
		path = path.includes("?") ? path.substring(0, path.indexOf("?")) : path;

		if (path === this.#path) {
			// exact match
			return Number.MAX_SAFE_INTEGER;
		}

		let matchStart = this.#matchStart;
		let currentPart,
			index,
			lastWildcard = false;

		for (let i = matchStart ? 0 : 1; i < this.#parts.length; i++, matchStart = false) {
			currentPart = this.#parts[i];
			if (currentPart === "") {
				lastWildcard = true;
				continue;
			} else {
				lastWildcard = false;
			}

			index = matchStart ? (path.startsWith(currentPart) ? 0 : -1) : path.indexOf(currentPart);
			if (index === -1) return 0;

			path = path.substring(index + currentPart.length);
		}
		if (!lastWildcard && path != "") {
			return 0;
		}
		return this.#matchLength;
	}
}

class ClickRule {
	/** @type {string} */
	#ruleId = undefined;
	/** @type {string} */
	#name = undefined;

	/**
	 * @param {string} ruleId
	 * @param {string} name
	 */
	constructor(ruleId, name) {
		this.#ruleId = ruleId;
		this.#name = name;
	}

	get ruleId() {
		return this.#ruleId;
	}

	get name() {
		return this.#name;
	}

	/**
	 * @param {Element} element
	 * @return {boolean}
	 */
	matches(element) {
		throw new Error("Not implemented");
	}

	/**
	 * @param {ServerRule} rule
	 * @return {ClickRule}
	 */
	static from(rule) {
		switch (rule.configuration.element) {
			case "ID":
				return new IdRule(rule.id, rule.configuration.selector, rule.slug);

			case "CLASS":
				return new ClassRule(rule.id, rule.configuration.selector, rule.slug);

			default:
				console.log("Unknown click rule element:", rule.configuration.element);
				return undefined;
		}
	}
}

class IdRule extends ClickRule {
	/** @type {string} */
	#id = undefined;

	/**
	 * @param {string} ruleId
	 * @param {string} id
	 * @param {string} name
	 */
	constructor(ruleId, id, name) {
		super(ruleId, name);
		this.#id = id;
	}

	matches(element) {
		return this.#id === element.id;
	}
}

class ClassRule extends ClickRule {
	/** @type {string} */
	#cls = undefined;

	/**
	 * @param {string} ruleId
	 * @param {string} cls
	 * @param {string} name
	 */
	constructor(ruleId, cls, name) {
		super(ruleId, name);
		this.#cls = cls;
	}

	matches(element) {
		return element.classList.contains(this.#cls);
	}
}

/**
 * @param {Array.<ViewRule>} rules
 * @param {string} url
 * @returns {ViewRule?}
 */
export function calculateBestRule(rules, url) {
	return rules
		.map((pathRule) => ({
			path: pathRule.path,
			name: pathRule.name,
			value: pathRule.computeMatch(url),
		}))
		.filter((evaluation) => evaluation.value > 0)
		.sort((e1, e2) => e2.value - e1.value)[0];
}

export class RuleSet {
	static #matchAttr = "data-analytics-matched-rule";
	static #trackDataAttr = "data-analytics-track";

	#config = {};
	#analytics = undefined;

	#rulesVersion = 0;
	/** @type {Array.<ViewRule>}*/
	#viewRules = [];
	/** @type {Object.<string, ClickRule>} */
	#clickRules = {};
	/** @type {number} */
	#clickRuleCount = 0;

	constructor(config) {
		this.#config = config;
		this.#addHistoryListeners();
		this.#addClickListener();
	}

	set analytics(analytics) {
		this.#analytics = analytics;
	}

	static #getCurrentUrl() {
		return window.location.pathname + window.location.search + window.location.hash;
	}

	#addHistoryListeners() {
		const onHistoryChange = this.#onHistoryChange.bind(this);

		const pushState = window.history.pushState;
		window.history.pushState = function () {
			pushState.apply(window.history, arguments);
			onHistoryChange(RuleSet.#getCurrentUrl());
		};

		const replaceState = window.history.replaceState;
		window.history.replaceState = function () {
			replaceState.apply(window.history, arguments);
			onHistoryChange(RuleSet.#getCurrentUrl());
		};

		window.addEventListener("popstate", () => this.#onHistoryChange(RuleSet.#getCurrentUrl()));
	}

	#onHistoryChange(url) {
		if (this.#viewRules.length === 0) return;

		const bestRule = calculateBestRule(this.#viewRules, url);

		if (bestRule) {
			this.#config.log && console.log("Matched path event:", url, bestRule.path);
			this.#analytics.page({}, { event: bestRule.name });
		}
	}

	#addClickListener() {
		window.addEventListener("click", (e) => this.#onClick(e.target));
	}

	/**
	 * @param {Element} element
	 */
	#onClick(element) {
		if (this.#clickRuleCount === 0) {
			return;
		}

		if (element.hasAttribute(RuleSet.#matchAttr)) {
			const ruleId = element.getAttribute(RuleSet.#matchAttr);
			const clickRule = this.#clickRules[ruleId];
			if (clickRule?.matches(element)) {
				this.#fireTrackEvent(element, clickRule);
				return;
			} else {
				element.removeAttribute(RuleSet.#matchAttr);
			}
		}

		const clickRule = Object.values(this.#clickRules).find((rule) => rule.matches(element));
		if (clickRule) {
			element.setAttribute(RuleSet.#matchAttr, clickRule.ruleId);
			this.#fireTrackEvent(element, clickRule);
		}
	}

	/**
	 * @param {Element} element
	 * @param {ClickRule} rule
	 */
	#fireTrackEvent(element, rule) {
		if (element.hasAttribute(RuleSet.#trackDataAttr)) {
			this.#analytics.track(rule.name, this.#parseElementData(element));
		} else {
			this.#analytics.track(rule.name);
		}
	}

	/**
	 * @param {Element} element
	 * @return {Object}
	 */
	#parseElementData(element) {
		const dataStr = element.getAttribute(RuleSet.#trackDataAttr);
		let err1, err2;

		try {
			return JSON.parse(dataStr);
		} catch (err) {
			// not in JSON format
			err1 = err;
		}

		try {
			const jsonDataStr =
				"{ " +
				dataStr
					.split(",")
					.map((assignment) =>
						assignment
							.trim()
							.split(":")
							.map((token) => `"${token.trim()}"`)
							.join(": "),
					)
					.join(", ") +
				" }";
			return JSON.parse(jsonDataStr);
		} catch (err) {
			// not in a recognized format
			err2 = err;
		}

		if (this.#config.log) {
			console.log(err1);
			console.log(err2);
		}

		return undefined;
	}

	/**
	 * @param {RuleSetResponse} response
	 * @param {string} firedAt
	 * @param {boolean} initialization
	 */
	processRules(response, firedAt, initialization) {
		const rules = response?.rules;
		const rulesVersion = Date.parse(response?.updatedOn || firedAt);

		if (this.#rulesVersion != 0 && this.#rulesVersion === rulesVersion) {
			return;
		}

		if (rules === null || rules === undefined) {
			this.#removeRules();
			this.#rulesVersion = rulesVersion;
		} else if (rules.length) {
			this.#removeRules();
			this.#applyRules(rules);
			this.#rulesVersion = rulesVersion;

			initialization && this.#onHistoryChange(RuleSet.#getCurrentUrl());
		}
	}

	#removeRules() {
		this.#viewRules = [];
		this.#clickRules = {};
		this.#clickRuleCount = 0;
	}

	/**
	 * @param {Array.<ServerRule>} rules
	 */
	#applyRules(rules) {
		rules.forEach((rule) => {
			switch (rule.type) {
				case "VIEW":
					this.#viewRules.push(new ViewRule(rule.configuration.path, rule.name));
					break;

				case "TRACK":
					this.#applyTrackRule(rule);
					break;

				default:
					this.#config.log && console.log("Unknown rule type:", rule.type);
			}
		});
	}

	/**
	 * @param {ServerRule} rule
	 */
	#applyTrackRule(rule) {
		// noinspection FallThroughInSwitchStatementJS
		switch (rule.configuration.type) {
			case "CLICK":
				const clickRule = ClickRule.from(rule);
				if (clickRule) {
					this.#clickRules[clickRule.ruleId] = clickRule;
					this.#clickRuleCount++;
					break;
				}

			default:
				this.#config.log && console.log("Unknown rule type:", `${rule.configuration.type}/${rule.configuration.element}`);
		}
	}
}