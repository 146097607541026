import { RuleSet } from "./ruleSet";
import { v4 as uuid } from "uuid";

// noinspection JSIgnoredPromiseFromCall
class CDPAnalyticsPlugin {
	// noinspection HttpUrlsUsage

	#libraryInfo = {
		name: "",
		version: "1.0.0",
	};

	#config = {};

	#loaded = false;
	#ready = false;
	#enabled = false;

	#analytics = undefined;
	#state = {};
	#page = {};

	/** @type {RuleSet} */
	#ruleSet;

	constructor(config, wrapped) {
		this.#config = config;
		this.#libraryInfo.name = wrapped ? "cdp-analytics.js" : "analytics-plugin-cdp.js";
		this.#ruleSet = new RuleSet(config);
		this.#config.log && console.log("Plugin config:", config);
	}

	static #getTime(timestamp) {
		return (timestamp ? new Date(timestamp) : new Date()).toISOString();
	}

	// noinspection JSUnusedLocalSymbols
	loaded(event) {
		!this.#loaded && this.#config.log && console.log("Plugin loaded");
		return (this.#loaded = true);
	}

	initialize(event) {
		this.#analytics = event.instance;
		this.#enabled = this.#config.enabled;
		this.#ruleSet.analytics = this.#analytics;
		this.#state = event.instance.getState();
		this.#config.log && console.log("Plugin initialized");
		this.#sendSavedEvents();
	}

	#sendSavedEvents() {
		const eventStorage = JSON.parse(window.localStorage.getItem("cdp_events") || "{}");
		const events = Object.keys(eventStorage).map((key) => eventStorage[key]);
		window.localStorage.setItem("cdp_events", "{}");

		if (events.length > 0) {
			this.#config.log && console.log("Sending previously saved events", events);
			this.#doCallApiForEvents(events);
		}
	}

	// noinspection JSUnusedLocalSymbols
	async ready(event) {
		this.#enabled = true;
		await this.#callApi(undefined); // load rules call
		this.#ready = true;
		this.#config.log && console.log("Plugin ready");
	}

	// this method does not seem to be called Analytics
	// noinspection JSUnusedLocalSymbols
	enablePlugin(event) {
		this.#enabled = true;
		this.#config.log && console.log("Enabled plugin");
	}

	// noinspection JSUnusedLocalSymbols
	disablePlugin(event) {
		this.#enabled = false;
		this.#config.log && console.log("Disabled plugin");
	}

	page(event) {
		const { properties, options, anonymousId, userId, meta } = event.payload;

		this.#page = {
			title: properties.title,
			url: properties.url,
			path: properties.path,
			referrer: document.referrer,
			search: properties.search,
		};

		// noinspection JSUnresolvedVariable
		const body = {
			anonymousId,
			channel: "browser",
			context: {
				userAgent: this.#state.context.userAgent,
			},
			integrations: {},
			messageId: meta.rid,
			properties: {
				title: this.#page.title,
				url: this.#page.url,
				...properties
			},
			sentAt: CDPAnalyticsPlugin.#getTime(meta.ts),
			type: "page",
			event: options?.event,
			userId: userId ? userId : undefined,
			version: this.#libraryInfo.version,
		};

		this.#callApi(body);
	}

	track(event) {
		const payload = event.payload;

		// noinspection JSUnresolvedVariable
		const body = {
			anonymousId: payload.anonymousId,
			channel: "browser",
			context: {
				library: this.#libraryInfo,
				page: this.#page,
				userAgent: this.#state.context.userAgent,
			},
			event: payload.event,
			integrations: {},
			messageId: payload.meta.rid,
			properties: payload.properties,
			sentAt: CDPAnalyticsPlugin.#getTime(payload.meta.ts),
			type: "track",
			userId: payload.userId ? payload.userId : undefined,
			version: this.#libraryInfo.version,
		};

		this.#callApi(body);
	}

	identify(event) {
		const payload = event.payload;

		// noinspection JSUnresolvedVariable
		const body = {
			anonymousId: payload.anonymousId,
			channel: "browser",
			context: {
				userAgent: this.#state.context.userAgent,
			},
			integrations: {},
			messageId: payload.meta.rid,
			properties: payload.properties,
			sentAt: CDPAnalyticsPlugin.#getTime(payload.meta.ts),
			traits: payload.traits,
			type: "identify",
			userId: payload.userId ? payload.userId : undefined,
			version: this.#libraryInfo.version,
		};

		this.#callApi(body);
	}

	group(groupId, traits, analytics) {
		traits = traits || {};

		const state = analytics.getState();
		const user = state.user || {};

		const body = {
			anonymousId: user.anonymousId ? user.anonymousId : undefined,
			channel: "browser",
			context: {
				userAgent: state.context.userAgent,
			},
			integrations: {},
			messageId: window.URL.createObjectURL(new Blob([])).substr(-36),
			sentAt: CDPAnalyticsPlugin.#getTime(),
			traits: traits,
			type: "group",
			userId: user.userId ? user.userId : undefined,
			groupId: groupId,
			version: this.#libraryInfo.version,
		};

		this.#callApi(body);
	}

	#callApi(event) {
		if (!this.#enabled) {
			return;
		}

		this.#config.log &&
			console.log(
				JSON.stringify(
					{
						logMessage: "Calling API",
						...event,
					},
					undefined,
					"  ",
				),
			);

		const eventStorageId = event?.messageId || uuid();
		CDPAnalyticsPlugin.#saveEvent(event, eventStorageId);

		this.#doCallApiAsync(event, eventStorageId);
	}

	async #doCallApiAsync(event, eventStorageId) {
		const { requestId, firedAt, response } = await this.#doCallApiForEvent(event);
		CDPAnalyticsPlugin.#removeEvent(event, eventStorageId);
		if (response === undefined) return; // all the retries have failed

		const body = await response.json();
		this.#config.log &&
			console.log(
				JSON.stringify(
					{
						logMessage: "API response",
						requestId: requestId,
						status: response.status,
						statusText: response.statusText,
						headers: response.headers,
						body,
					},
					undefined,
					"  ",
				),
			);

		this.#ruleSet.processRules(body, firedAt, event === undefined);
	}

	static #saveEvent(event, storageId) {
		const eventStorage = JSON.parse(window.localStorage.getItem("cdp_events") || "{}");
		eventStorage[storageId] = event;
		window.localStorage.setItem("cdp_events", JSON.stringify(eventStorage));
	}

	static #removeEvent(event, storageId) {
		const eventStorage = JSON.parse(window.localStorage.getItem("cdp_events") || "{}");
		delete eventStorage[storageId];
		window.localStorage.setItem("cdp_events", JSON.stringify(eventStorage));
	}

	async #doCallApiForEvent(event) {
		return this.#doCallApiWithRetry(event ? [event] : [], event?.messageId || uuid(), this.#config.retry);
	}

	async #doCallApiForEvents(events) {
		return this.#doCallApiWithRetry(events || [], uuid(), this.#config.retry);
	}

	async #doCallApiWithRetry(events, requestId, retries) {
		const firedAt = CDPAnalyticsPlugin.#getTime();

		try {
			const response = await fetch(this.#config.apiUrl, {
				method: "POST",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin", // include, *same-origin, omit
				headers: {
					"Content-Type": "application/json",
				},
				redirect: "follow",
				referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
				body: JSON.stringify({
					requestId,
					firedAt,
					datasourceId: this.#config.datasourceId,
					events,
				}),
			});

			if (response.status >= 500) {
				// noinspection ExceptionCaughtLocallyJS
				throw new Error(`Error analytics status code: HTTP ${response.status} ${response.statusText}`);
			} else {
				return { requestId, firedAt, response };
			}
		} catch (err) {
			this.#config.log && console.log(`${this.#config.retry - retries + 1}. try failed`, err);

			if (retries > 1) {
				return this.#doCallApiWithRetry(events, requestId, retries - 1);
			} else {
				this.#config.log && console.log("Retries exhausted");
				return { requestId, firedAt, response: undefined };
			}
		}
	}
}

// noinspection JSUnusedGlobalSymbols
export function create(config, wrapped) {
	const defaultConfig = {
		log: false,
		retry: 5,
	};

	const mergedConfig = Object.assign({}, defaultConfig, config);
	if (typeof mergedConfig.retry !== "number") {
		// noinspection JSCheckFunctionSignatures
		const retry = parseInt(mergedConfig.retry);
		mergedConfig.retry = Math.max(isNaN(retry) ? defaultConfig.retry : retry, 1);
	}

	const plugin = new CDPAnalyticsPlugin(mergedConfig, wrapped);

	return {
		name: "cdp-analytics",

		methods: {
			group: function (groupId, traits) {
				return plugin.group(groupId, traits, this.instance);
			},
		},

		loaded: function (event) {
			return plugin.loaded(event);
		},

		initialize: function (event) {
			return plugin.initialize(event);
		},

		ready: async function (event) {
			return plugin.ready(event);
		},

		enablePlugin(event) {
			return plugin.enablePlugin(event);
		},

		disablePlugin(event) {
			return plugin.disablePlugin(event);
		},

		page: function (event) {
			return plugin.page(event);
		},

		track: function (event) {
			return plugin.track(event);
		},

		identify: function (event) {
			return plugin.identify(event);
		},
	};
}